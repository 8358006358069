import React, { useEffect, useRef, useState, useContext } from "react";
import Loader from "react-js-loader";
import DataContext from '../Elements/eventContext';
import multiCurrency from "../Elements/multi_currrency"; 
import sessionCartData from "../Elements/cart_session_data"; 
import CouponModal from "./coupon_modal";
import { ApiService } from "../Services/apiservices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
function PaymentModal() {
    const Navigate = useNavigate();
    const sessionData = sessionCartData();
    const addressSessionData = sessionData[0];
    const cartSessionData = sessionData[1];
    const couponSummary = sessionData[2];
    const cartSummary = sessionData[3];
    const contextValues = useContext(DataContext);
    const [initialLoading, setInitialLoading] = useState(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("pay-online");
    const [shippingCharge, setShippingCharge] = useState(0);
    const [cashHandlingCharge, setCashHandlingCharge] = useState(0);
    const didMountRef = useRef(true);
    const [Razorpay] = useRazorpay();
    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setAddressSession(sessionData[0])
            contextValues.setCouponSession(sessionData[2])
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                cartSessionDataFetch();
                getUserSelectedAddress()
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
            getSettingsData()
        }
        didMountRef.current = false;
    }, [contextValues]);

    const cartSessionDataFetch = () => {
        const dataString = {
            product_id: '',
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                if (res.data.resCartData.length == 0) {
                    window.location.reload()
                }
            }
        });
    } 

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                contextValues.setSettingData(res.sitesettings);
                contextValues.setSettingImageBaseUrl(res.setting_image_path);
                if (res.sitesettings.admin_cod_status === 1) {
                    setPaymentMethod('COD')  
                    if(Number(contextValues.cartSummary.itemTotal) > 0){
                        setCashHandlingCharge(Math.round((parseFloat(contextValues.cartSummary.itemTotal) * res.sitesettings.admin_cash_handeling_charge)/100))
                    }else{
                        setCashHandlingCharge(0)
                    }
                    setTimeout(() => {
                        calculateShippingAmount('1')
                    }, 100);
                } else {
                    setPaymentMethod('pay-online')
                    setCashHandlingCharge(0)
                    setTimeout(() => {
                        calculateShippingAmount('0')
                    }, 100);
                }
            }
        });
    };

    const calculateShippingAmount = (paymentType) => {
        const dataString = {
            itemtotal: parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount),
            ua_id: contextValues.userSelectedAddress.ua_id,
            cart_data: contextValues.cartSessionData,
            payment_type: paymentType,
            payment_type_admin: contextValues.settingData.admin_cod_status
        };

        ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
            if (res.status === "success") { 
                setShippingCharge(res.shipping_amount); 
                setSpinnerLoading(false);
            }
        });
    };

    const deleteToCart = async (productValue) => {
        setSpinnerLoading(true)
        if (localStorage.getItem("USER_TOKEN")) {
            const dataString = {
                cart_id: productValue.cart_id,
            };
            ApiService.postData("removecartproduct", dataString).then((res) => {
                if (res.data.status === "success") {
                    localStorage.removeItem("COUPON_SESSION");
                    cartSessionDataFetch()
                    getSettingsData()
                    setTimeout(() => {
                        setSpinnerLoading(false);
                    }, 500);
                } else {
                    setTimeout(() => {
                        setSpinnerLoading(false);
                    }, 500);
                }
            });
        } 
    };

    const plusToCart = async (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        if (localStorage.getItem("USER_TOKEN")) {
            ApiService.postData("plus-to-cart", productValue).then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("COUPON_SESSION");
                    cartSessionDataFetch()
                    getSettingsData()
                    toast.success('Cart Updated Successfully');
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    toast.error(res.message);
                    contextValues.setSpinnerCubLoader(0)
                }
            });
        }  
    };

    const minustocart = (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        if (localStorage.getItem("USER_TOKEN")) {
            ApiService.postData("minus-to-cart", productValue).then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("COUPON_SESSION");
                    cartSessionDataFetch()
                    getSettingsData()
                    toast.success('Cart Updated Successfully');
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    toast.error(res.message);
                    contextValues.setSpinnerCubLoader(0)
                }
            });
        }  
    };

    const getUserSelectedAddress = () => {
        ApiService.fetchData("get-user-selected-address").then((res) => {
            if (res.status === "success") {
                localStorage.setItem("ADDRESS_SESSION", JSON.stringify(res.resUserAddress));
                contextValues.setUserSelectedAddress(res.resUserAddress);
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            } else if (res.status === "session_out") {
                contextValues.setUserToken('')
                togglePaymentModal()
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
        });
    };

    const togglePaymentModal = () => {
        contextValues.setTogglePaymentModal(!contextValues.togglePaymentModal)
    }

    const couponModal = () => {
        contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
    }

    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
    }

    const selectPaymentMode = (mode) => {
        setPaymentMethod(mode)
        if (mode === 'COD') {
            setSpinnerLoading(true);
            calculateShippingAmount('1')
            if(Number(contextValues.cartSummary.itemTotal) > 0){
                setCashHandlingCharge(Math.round((parseFloat(contextValues.cartSummary.itemTotal) * parseFloat(contextValues.settingData.admin_cash_handeling_charge))/100))
            }else{
                setCashHandlingCharge(0)
            }
        } else {
            setSpinnerLoading(true);
            calculateShippingAmount('0')
            setCashHandlingCharge(0)
        }
    };

    const choutOutProccess = () => {
        if (paymentMethod === '') {
            toast.error('Please select Payment Method')
            return;
        }
        if (parseFloat(contextValues.settingData.admin_min_order) > parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0)) {
            toast.error('Minimum Order Value is ₹'+ contextValues.settingData.admin_min_order)
            return;
        }
        if (paymentMethod !== 'COD') { 
            if (contextValues.settingData.admin_payment_active === 0) {
            } if (contextValues.settingData.admin_payment_active === 1) {
            } if (contextValues.settingData.admin_payment_active === 2) {
                createOrder()
            } else {
            }
        } else {
            const dataString = {
                cartSummary: contextValues.cartSummary,
                parsedAddressSession: addressSessionData,
                parsedCartSession: contextValues.cartSessionData,
                parsedCouponSession: couponSummary,
                paymentMethod: paymentMethod,
                shippingCharge: parseFloat(shippingCharge),
                textarea: '',
                cashHandlingCharge: parseFloat(cashHandlingCharge),
                amount: parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + parseFloat(shippingCharge) + parseFloat(cashHandlingCharge)
            };
            setSpinnerLoading(true)
            ApiService.postData("makecodorder", dataString).then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("CART_SESSION")
                    localStorage.removeItem("ADDRESS_SESSION")
                    localStorage.removeItem("COUPON_SESSION")
                    Navigate('/thankyou/' + res.order_number)
                } else {
                    setSpinnerLoading(false)
                }
            });
        }
    }; 

    const createOrder = () => {
        const dataString = {
            cartSummary: contextValues.cartSummary,
            parsedAddressSession: addressSessionData,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: couponSummary,
            paymentMethod: paymentMethod,
            shippingCharge: parseFloat(shippingCharge),
            textarea: '',
            amount: parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + parseFloat(shippingCharge)
        };
        setSpinnerLoading(true)
        ApiService.postData("createtemporder", dataString).then((res) => {
            if (res.status === "success") {
                setSpinnerLoading(false)
                const orderOptions = {
                    key: res.rowAdminData.admin_razorpay_key,
                    amount: res.row_temp_trans.total_amount * 100,
                    currency: "INR",
                    name: "99pandit.com",
                    description: "Order Amount",
                    image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
                    order_id: res.row_temp_trans.temp_razorpay_order_id,
                    handler: (response) => {
                        RazorPayProcess(response, res.row_temp_trans.temp_trans_order_id);
                    },
                    prefill: {
                        name: `${addressSessionData.ua_name}`,
                        email: addressSessionData.ua_email,
                        contact: addressSessionData.ua_mobile,
                    },
                    theme: {
                        color: "#aa2614",
                    },
                };
                const rzpay = new Razorpay(orderOptions);
                rzpay.open();
            } else {
                setSpinnerLoading(false);
            }
        });
    };


    const RazorPayProcess = (response, orderId) => {
        const paymentData = {
            orderId: orderId,
            paymentId: response.razorpay_payment_id,
            cartSummary: contextValues.cartSummary,
            parsedAddressSession: addressSessionData,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: couponSummary,
            paymentMethod: paymentMethod,
            shippingCharge: parseFloat(shippingCharge),
            textarea: '',
            amount: parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + parseFloat(shippingCharge)
        };
        setSpinnerLoading(true)
        ApiService.postData("paymentprocessing", paymentData).then((result) => {
            if (result.status === "success") {
                setSpinnerLoading(false);
                Navigate('/thankyou/' + result.order_number)
            } else {
                setSpinnerLoading(false);
                toast.error("Payment processing failed. Please try again.");
            }
        });
    };
    return (
        <>
            <div className="paymentModal" backdrop="static">
                {spinnerLoading && <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div>}
                <div className="checkoutModalHeader">
                    <div className="checkoutModalHeaderTitle" onClick={(e) => togglePaymentModal()}>
                        <i className="d-icon-arrow-left mr-10"></i>
                        <img src="/img/logo.png" className="checkoutlogo"></img>
                    </div>
                    <img src="/img/100SECURE.png" className="wd-100"></img>
                </div>
                <div className="paymentModalContent">
                    {initialLoading ? <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div> : <>
                        {contextValues.cartSessionData.length > 0 && (
                            <>
                                {/* Order Summary */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummary" aria-expanded="false" aria-controls="orderSummary">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-shopping-bag-3-line ri-lg mr-10"></i>
                                            <span>Order Summary</span>
                                        </div>
                                        <div className="itemscount"><span className="mr-10">{contextValues.cartSessionData.length} Items</span> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                                    </div>
                                    <div id="orderSummary" className="collapse">
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <div className="checkooContent" key={value.product_id}>
                                                    <div className="ccocbox">
                                                        <div className="ccocbox-product-media">
                                                            <a href={"/product/" + value.product_slug}>
                                                                <img src={value.product_image} />
                                                            </a>
                                                        </div>
                                                        <div className="ccocbox-product-details">
                                                            <div className="ccocbox-product-title">
                                                                <a href={"/product/" + value.product_slug} className="ccocbox-product-name">{value.product_name}</a>
                                                                <a href="javascript:void(0)" className="ccocbox-trash" onClick={(e) => deleteToCart(value)}><i className="ri-delete-bin-6-line"></i></a>
                                                            </div>
                                                            <div className="ccocbox-product-footer">
                                                                <div className="qtyadd">
                                                                    {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : <>
                                                                        {value.quantity > 1 ? <span onClick={(e) => minustocart(value, index + Number(1))}><i className="ri-subtract-line"></i></span> : <span><i className="ri-subtract-line"></i></span>}
                                                                        <span>{value.quantity}</span>
                                                                        <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                                                                    </>
                                                                    }
                                                                </div>
                                                                <div className="ccocbox-product-price">{multiCurrency(value.product_selling_price)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* Price DEtails */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#priceDetails" aria-expanded="false" aria-controls="priceDetails">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-money-rupee-circle-line ri-lg mr-10"></i>
                                            <span>Price Details</span>
                                        </div>
                                        <div className="itemscount">
                                            <div className="itemprice mr-10">
                                                <ins className="new-price">{multiCurrency(Number(contextValues.cartSummary.itemTotal) - Number(contextValues.cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge) + parseFloat(cashHandlingCharge))}</ins>
                                            </div>
                                            <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                                    </div>
                                    <div id="priceDetails" className="collapse">
                                        <div className="checkooContent">
                                            <ul className="checkooPricelist">
                                                <li>
                                                    <span>Subtotal</span>
                                                    <span>{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                                                </li>
                                                <li>
                                                    <span>Discount on MRP</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.cartSummary.discount)}</span>
                                                </li>
                                                <li>
                                                    <span>Coupon Discount</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                                </li>
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>{multiCurrency(shippingCharge)}</span>
                                                </li>
                                                {parseFloat(cashHandlingCharge) > 0 ? <li>
                                                    <span>Cash Handling Charge ({contextValues.settingData.admin_cash_handeling_charge}%)</span>
                                                    <span>{multiCurrency(cashHandlingCharge)}</span>
                                                </li>:null}
                                                <li className="fw700" style={{ marginTop: "10px", fontSize: '14px' }}>
                                                    <span>Total</span>
                                                    <span>{multiCurrency(Number(contextValues.cartSummary.itemTotal) - Number(contextValues.cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + Number(shippingCharge) + parseFloat(cashHandlingCharge))}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Coupon Section */}
                                {contextValues.couponSession.promo_id > 0 ?
                                    <div className="checkoo mb-15">
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <div style={{ lineHeight: '20px' }}>
                                                    <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                    <span className="tx-12">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                                </div>

                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="checkoo mb-15" onClick={(e) => couponModal()}>
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <span>Coupons</span>
                                            </div>
                                            <div className="itemscount"><i className="ri-arrow-right-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                }
                                {/* Address Section */}
                                <div className="checkoo mb-30">
                                    <div className="checkooHeader">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-map-pin-line ri-lg mr-10"></i>
                                            <span>Delivery Address</span>
                                        </div>
                                        <div className="itemscount" onClick={(e) => togglePaymentModal()}><i className="ri-edit-box-line ri-lg"></i></div>
                                    </div>
                                    <div className="addressselbox">
                                        <div className="schooseaddress">
                                            {contextValues.userSelectedAddress.ua_house_no}, {contextValues.userSelectedAddress.ua_city_name}, {contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_pincode}
                                        </div>
                                    </div>
                                </div>
                                {/* Payment Section */}
                                <h6 className="tx-14 mb-20">Payment Methods</h6>
                                {contextValues.settingData.admin_cod_status === 1 ?
                                    <div className={`paymentbox ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMode('COD')}>
                                        <div className="paymentboxselect"></div>
                                        <div className="paymentboxtext ml-15">
                                            <h6 className="mb-0 tx-12">Cash on delivery</h6>
                                            <p className="mb-0 tx-11 tx-gray">Pay with cash</p>
                                        </div>
                                       
                                    </div>
                                    : null}
                                <div className={`paymentbox ${paymentMethod === 'pay-online' ? 'active' : ''}`} onClick={(e) => selectPaymentMode('pay-online')}>
                                    <div className="paymentboxselect"></div>
                                    <div className="paymentboxtext ml-10">
                                        <h6 className="mb-1 tx-15 fw600">Pay Online</h6>
                                        <p className="mb-0 tx-11">(UPI, Cards, Wallets, NetBanking)</p>
                                    </div>
                                    <div className="paymentboxIcon" style={{ marginLeft: 'auto' }}>
                                        <img src="/img/UPI.svg"></img>
                                        <img src="/img/visa.svg"></img>
                                        <img src="/img/master.svg"></img>
                                        <img src="/img/rupay.svg"></img>
                                    </div>
                                </div>
                                <div className="checkoutModalFooter">
                                    <button type="button" className="btn btn-primary btn-large btn-full" onClick={(e) => choutOutProccess()}>Pay Now</button>
                                </div>
                            </>
                        )}
                    </>
                    }
                </div>
                {contextValues.toggleCouponModal && (<CouponModal />)}
            </div>
        </>

    )
}

export default PaymentModal
