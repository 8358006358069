import React, { useEffect, useRef, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import { ApiService } from "../../Components/Services/apiservices";
import SpinnerLoader from '../../Components/Elements/spinner_loader';
import sessionCartData from "../../Components/Elements/cart_session_data";
import DataContext from "../Elements/eventContext";
import OTPInput from "react-otp-input";
function LoginModal({ showmodal, onChildData }) {
  const dataArray = sessionCartData();
  const [steps, setSteps] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [otp, setOtp] = useState('');
  const [regDetails, setRegDetails] = useState({
    user_fname: "",
    user_email: "",
    session_data: dataArray[1],
  });
  const [userLoginDetails, setUserLoginDetails] = useState({
    user_mobile: "",
  });
  const contextValues = useContext(DataContext);

  const userLogin = () => {
    if (userLoginDetails.user_mobile == '') {
      const myElement = document.getElementsByName("user_mobile")[0];
      if (myElement) {
        myElement.style.border = '1px solid red';
      }
      return false;
    }
    if (!validNumber.test(userLoginDetails.user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    }
    setIsLoading(true)
    ApiService.loginProccessPostData("user-login", userLoginDetails).then((res) => {
      if (res.status === "success") {
        localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
        setErrorMessage('')
        setSteps(2);
        setIsLoading(false)
        setResendTimer(30);
      } else {
        setErrorMessage(res.message);
        setIsLoading(false)
      }
    }).catch(() => {
      setIsLoading(false)
    })


  };
  const onTodoChange = (e) => {
    setErrorMessage('')
    setSuccessMessage('')
    const { name, value } = e.target;
    setUserLoginDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value !== '') {
      e.target.style.border = '';
    }
  };

  const onChangeOtp = (otp) => {
    setErrorMessage("");
    setSuccessMessage('')
    setOtp(otp);
    if (otp.length === 4) {
      userLoginOtpProcess(otp);
    }
  };
  const userLoginOtpProcess = (otp) => {
    setspinnerLoading(true)
    const dataString = {
      user_otp: otp,
      session_data: dataArray[1],
    };
    ApiService.loginProccessPostData("otpverify", dataString).then((res) => {
      if (res.status === "success") {
        if (res.user_status === 'new_register') {
          setTimeout(() => {
            localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
            setSteps(3)
            setspinnerLoading(false)
          }, 500);
        } else if (res.user_status == 'already_registered') {
          localStorage.removeItem("CART_SESSION");
          setSuccessMessage(res.message)
          setspinnerLoading(false)
          localStorage.removeItem("TEMP_USER_TOKEN");
          setTimeout(() => {
            setSuccessMessage('')
            localStorage.setItem("USER_TOKEN", res.user_token);
            window.location.reload();
          }, 500);
        }
      }
      else if (res.status == 'error' && res.message == 'Session expired') {
        setErrorMessage(res.message)
        setspinnerLoading(false)
        setTimeout(() => {
          setErrorMessage('')
          localStorage.removeItem("TEMP_USER_TOKEN");
          window.location.reload();
        }, 500);
      }
      else {
        setTimeout(() => {
          setErrorMessage(res.message);
          setspinnerLoading(false)
        }, 500);
      }
    }).catch(() => {
      setspinnerLoading(false)
    });

  };



  const goBack = () => {
    setErrorMessage("");
    setSuccessMessage('')
    setUserLoginDetails({
      user_mobile: "",
    })
    setRegDetails({
      user_fname: "",
      user_email: "",
    })
    setOtp("");
    setSteps(1);
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer(prev => prev - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [resendTimer]);


  const resendOTP = () => {
    setErrorMessage("");
    setResendTimer(30);
    setOtp("");
    setspinnerLoading(true)
    const dataString = {
      user_token: '',
    }
    ApiService.loginProccessPostData('resendotp', dataString).then((res) => {
      if (res.status === "success") {
        setTimeout(() => {
          setspinnerLoading(false);
        }, 200);
      } else if (res.status == 'error' && res.message == 'Session expired') {
        setErrorMessage(res.message);
        setTimeout(() => {
          setErrorMessage('')
          localStorage.removeItem("TEMP_USER_TOKEN");
          window.location.reload();
        }, 500);
      }
      else {
        setErrorMessage(res.message);
        setTimeout(() => {
          setspinnerLoading(false);
        }, 500);
      }
    }).catch(() => {
      setspinnerLoading(false)
    });
  }

  const onSignUpInputChange = (e) => {

    setErrorMessage('')
    setSuccessMessage('')
    const { name, value } = e.target;
    setRegDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value !== '') {
      e.target.style.border = '';
    }
  };


  const userRegisterProcess = (e) => {
    let counter = 0;
    const myElements = document.getElementsByClassName('registerrequired');
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter == 0) {

      if (!validEmail.test(regDetails.user_email)) {
        setErrorMessage("Please Enter Valid Email");
        return false;
      }

      setIsLoading(true)
      ApiService.loginProccessPostData("register", regDetails).then((res) => {
        if (res.status === "success") {
          localStorage.removeItem("TEMP_USER_TOKEN");
          localStorage.removeItem("CART_SESSION");
          setSuccessMessage(res.message)
          localStorage.setItem("USER_TOKEN", res.user_token);
          setTimeout(() => {
            setSuccessMessage('')
            window.location.reload();
          }, 500);
        }
        else if (res.status == 'error' && res.message == 'Session expired') {
          setErrorMessage(res.message);
          setTimeout(() => {
            localStorage.removeItem("TEMP_USER_TOKEN");
            window.location.reload();
          }, 500);
        }
        else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setIsLoading(false)
          }, 500);
        }
      }).catch(() => {
        setIsLoading(false)
      });
    }

  }

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  return (
    <>
      <Modal show={contextValues.toggleLoginModal} onHide={(e) => loginModal()} className="lrpop">
        {spinnerLoading && (<SpinnerLoader />)}
        <button onClick={(e) => loginModal()} className="pop-close"><i className="d-icon-times"></i></button>
        <div className='loginContant'>
          {steps == 1 && (
            <>
              <div className='text-center'>
                <img src="/img/logo.png" className='mb-2' alt="" width="150" height="auto" />
                <h5 className="tx-theme">Log in or Sign up</h5>
                <p className="tx-color-02">For Better Experience, Order tracking & Regular updates</p>
              </div>
              {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
              {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
              <div className="form-group country-input mb-3">
                <input
                  type="number"
                  name="user_mobile"
                  className="form-control"
                  value={userLoginDetails.user_mobile}
                  onChange={(e) => onTodoChange(e)}
                  placeholder="Enter Mobile Number"
                />
                <span className="country-code">+91</span>
              </div>
              <div className="d-grid mb-4">
                <Button className="btn-primary btn01" onClick={userLogin}>
                  {isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Login")}</Button>
              </div>
              <p className="text-center tx-12 tx-color-03">By continuing I agree with the <a href="/privacy-policy" className="tx-theme">Privacy Policy</a> and <a href="/terms-conditions" className="tx-theme">Terms &amp; Conditions</a></p>
            </>
          )}
          {steps == 2 && (
            <>
              <div className='text-center mb-3'>
                <h5 className="tx-theme">Verify Mobile number</h5>
                <p className="tx-color-02">
                  Otp has been sent to your registered mobile number {userLoginDetails.user_mobile}.<a href='javascript:;' className='tx-primary' onClick={(e) => goBack()}>Change</a>
                </p>
              </div>
              {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
              {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
              <div className="form-group mb-15 otp-input">
                                       <OTPInput
                                            value={otp}
                                            onChange={onChangeOtp}
                                            numInputs={4}
                                            renderInput={(props) => <input {...props} />}
                                        />
              </div>
              {resendTimer === 0 ? (
                <p className='tx-gray text-center'>
                  Did not receive OTP? <a href='javascript:void(0);' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
                </p>
              ) : (
                <p className='tx-gray text-center'>Resend code in {resendTimer} sec</p>
              )}
              {spinnerLoading && (
                <div className="siteloader tx-center">
                  <img src="/img/loader.webp" alt="Loading..." width="30" height="30" />
                </div>
              )}
            </>
          )}
          {steps == 3 && (
            <>
              <div className="text-center mb-3">
                <h5 className="tx-theme">Complete Your Registration</h5>
                <p className="tx-color-02">
                  Complete your details and fill the form below
                </p>
              </div>
              {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
              {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="user_fname"
                  className="form-control registerrequired"
                  value={regDetails.user_fname}
                  onChange={(e) => onSignUpInputChange(e)}
                  placeholder="Full Name"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="user_email"
                  className="form-control registerrequired"
                  value={regDetails.user_email}
                  onChange={(e) => onSignUpInputChange(e)}
                  placeholder="Email Address"
                />
              </div>
              <div className="d-grid mb-4">
                <Button className="btn-primary btn01" onClick={userRegisterProcess}>Register</Button>
              </div>
            </>
          )}
        </div>

      </Modal>
    </>
  );
}
export default LoginModal;
