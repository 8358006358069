// const API_URL =   "https://www.tamolo.in/projects/99pandit/csadmin/api/"
const API_URL = "https://shop.99pandit.com/csadmin/api"
const BASE_URL =   "https://shop.99pandit.com/"  
const FRONT_URL =   "https://shop.99pandit.com/" 
const LOGO_IMAGE =   "/img/logo.png" 

const DEFAULT_IMAGE = "/img/defaultimage.png";
const CATEGORIES = [];
const themesetting = {}; 
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE,LOGO_IMAGE };
